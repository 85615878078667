import { useLocation } from 'react-router'
import { isSmallPageRoute } from '~/utils/location/location.route.helpers'

export function GetPageWidth({
	size,
	marginY,
}: {
	size: 'sm' | 'md'
	marginY: boolean
}) {
	const location = useLocation()
	const isSmallPage = isSmallPageRoute(location.pathname)
	let classBuilder = ''
	if (marginY) {
		classBuilder +=
			(isSmallPage ? 'mt-8 md:mt-24 ' : 'mt-24 ') + 'mb-20 md:mb-10 '
	}
	if (size === 'sm') {
		classBuilder += 'mx-auto max-w-5xl px-4'
	} else if (isSmallPage) {
		classBuilder += 'px-4 max-w-7xl md:px-10 lg:px-14 mx-auto'
	} else {
		classBuilder += 'px-4 md:px-10 lg:px-14 xl:px-20 mx-auto'
	}

	return classBuilder
}
