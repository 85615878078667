import { GetPageWidth } from './container.helper'
import { cn } from '~/utils/misc'

interface ContainerProps {
	className?: string
	children?: React.ReactNode
	size?: 'sm' | 'md'
	marginY?: boolean
}

export default function Container({
	className,
	children,
	size = 'md',
	marginY = true,
}: ContainerProps) {
	const classes = cn(GetPageWidth({ size, marginY }), className)
	return <div className={classes}>{children}</div>
}
