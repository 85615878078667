export const isRootRoute = (pathname: string): boolean => {
	return /^\/[a-z]{2}$/.test(pathname) || pathname === '/'
}

export const isPlaceRoute = (pathname: string): boolean => {
	return pathname.includes('/place')
}

export const isMapRoute = (pathname: string): boolean => {
	return pathname.includes('/map')
}

export const isEventRoute = (pathname: string): boolean => {
	return pathname.includes('/events')
}

export const isInfoRoute = (pathname: string): boolean => {
	const isInfoRoute =
		/(login|onboarding|forgot-password|reset-password|signup|verify)/.test(
			pathname,
		)
	return isInfoRoute
}

export const isSmallPageRoute = (pathname: string): boolean => {
	const isSmallPage =
		/events|profile|favorites|my-events|about|terms-and-conditions|privacy-policy|promote-event|info|guide|users/.test(
			pathname,
		) && /^(?!.*\/admin).*/.test(pathname)
	return isSmallPage
}
